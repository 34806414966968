@font-face {
  font-family: "SF Pro";
  src: url("../assets/fonts/SF-Pro.ttf");
}

@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("../assets/fonts/PlusJakartaText-BoldItalic.otf");
}

.App {
  text-align: center;
  align-items: center;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.banner-wrapper {
  background-color: #002202;
  margin-top: -80px;
}

.banner-container {
  background-image: url(../assets/images/main-background.png);
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: -66%;
  background-size: cover;
  height: 1393px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.banner-container .banner-action {
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
}

.banner-container .banner-action .heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.banner-container .banner-action .heading .item1 {
  width: fit-content;
  border: 2px solid #141414;
  padding: 4px 16px;
  border-radius: 32px;
  background-color: white;
  font-size: 14px;
  font-weight: 600px;
}

.banner-container .banner-action .heading .item2 {
  font-size: 72px;
  font-weight: 700;
  color: white;
}

.banner-container .banner-action .heading .item3 {
  font-size: 24px;
  font-weight: 400;
  color: white;
}

.banner-container .banner-action .button-wrapper {
  display: flex;
  gap: 16px;
}

.banner-container .banner-action .button-wrapper .join-button {
  border-radius: 8px;
  border: none;
  background-color: #91ee87;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.4px;
}

.banner-container .banner-action .button-wrapper .down-button {
  border-radius: 8px;
  border: none;
  background-color: #91ee87;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.4px;
}

.banner-container .banner-action .button-wrapper .reserve-button {
  border-radius: 8px;
  border: none;
  background-color: #ffffff;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.4px;
}

.banner-container .banner-action .button-wrapper .watch-button {
  border-radius: 8px;
  border: 1px solid white;
  padding: 12px 24px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  background-color: transparent;
  display: flex;
  gap: 6px;
  align-items: center;
  cursor: pointer;
}

.banner-container .banner-action .apps-wrapper,
.footer-section-wrapper .footer-section-main .apps-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #cfcfcf;
}

.banner-container .banner-action .apps-wrapper .app-button,
.footer-section-wrapper .footer-section-main .apps-wrapper .app-button {
  background-color: transparent;
  border: 1px solid #b8f1ad;
  border-radius: 32px;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 500;
  color: #b8f1ad;
  display: flex;
  gap: 4px;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}

.menu-section {
  width: 100%;
  padding-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  justify-content: center;
  top: 0;
  z-index: 1000;
}

.menu-wrapper {
  width: calc(100% - 75px);
  max-width: 1000px;
  background: white;
  height: 56px;
  padding: 0 8px 0 20px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-wrapper .link {
  font-size: 12px;
  font-weight: 600;
  padding: 7px 12px;
  cursor: pointer;
  text-decoration: none;
  color: #141414;
}

.menu-wrapper .link.active {
  background-color: #e6e6e6;
  border-radius: 8px;
}

.menu-wrapper .join-button {
  height: 40px;
  border-radius: 8px;
  border: none;
  background-color: #91ee87;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #141414;
}

.menu-wrapper .part1,
.menu-wrapper .part2 {
  display: flex;
  align-items: center;
  gap: 16px;
}

.menu-wrapper .proud-title {
  color: #141414;
  font-size: 18px;
  font-family: "SF Pro";
  font-weight: 590;
  line-height: 24px;
  word-wrap: break-word;
}

.banner-wrapper .banner-container .banner-image {
  height: 881px;
  width: 100%;
  max-width: 1440px;
  background-image: url(../assets/images/Handholdphone.png);
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: -10%;
  background-size: cover;
  z-index: 1;
}

.banner-card {
  position: absolute;
}

.banner-card.card1 {
  top: 46%;
  max-width: calc(100% - 50px);
  z-index: 2;
}

.banner-card.card2 {
  top: 49%;
  right: 6%;
  max-width: calc(18%);
}

.banner-left-card-container {
  position: absolute;
  top: 50%;
  left: 5%;
  width: 21%;
  display: flex;
  align-items: end;
  flex-direction: column;
  gap: 69px;
}

.banner-left-card-container .card3 {
  width: 100%;
}

.banner-left-card-container .card4 {
  width: 72%;
}

.banner-wrapper .floating-banner-text-wrapper {
  position: absolute;
  bottom: -10px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.banner-wrapper .floating-banner-text {
  color: white;
  font-size: 200px;
  font-family: "Plus Jakarta Sans";
  font-style: italic;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 200px;
  display: flex;
  flex-wrap: nowrap;
  animation: horizontal-scroll 10s linear infinite;
}

.section-wrapper,
.reserve-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  text-align: center;
}

.section-wrapper .header-title {
  font-size: 48px;
  font-weight: bold;
}

.reserve-section {
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  border-radius: 8px;
  width: 1180px;
  height: 404px;
  align-items: center;
  justify-content: center;
  row-gap: 40px;
}

.reserve-section .reserve-header {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: center;
}

.reserve-section .reserve-header .android-summary {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: #00530d;
}

.reserve-section .reserve-header .reserve-content {
  font-size: 44px;
  font-weight: 700;
  letter-spacing: -1.6px;
  color: #1C1C1D;
}

.reserve-section .form-part {
  display: flex;
  gap: 8px;
  /* align-items: center; */
}

.reserve-wrapper .form-part .input-wrapper {
  position: relative;
}

.reserve-section .form-part .input-email,
.reserve-section .form-part .input-username {
  border-width: 1px;
  border-radius: 4px;
  border-color: #1C1C1D;
  width: 227px;
  height: 56px;
  font-size: 15px;
  font-weight: 400;
  color: #1C1C1D;
  padding-left: 42px;
}

.reserve-section .form-part .error-field {
  border-color: #BF002B;
  border-width: 2px;
}

.reserve-section .form-part .error-text {
  text-align: left;
  font-size: 13px;
  font-weight: 400;
  color: #BF002B;
}

.reserve-section .form-part .icon-style {
  position: absolute;
  left: 12px;
  top: 20px;
}

.reserve-section .form-part .claim-button {
  width: 100px;
  height: 56px;
  border-radius: 8px;
  background-color: #1C1C1D;
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;
  border-width: 3px;
  cursor: pointer;
}

.reserve-section .form-part .claim-button:active {
  /* background-color: #4b4b4b; */
}

.reserve-section .reserve-summary {
  font-size: 15px;
  font-weight: 400;
  color: #555558;
  letter-spacing: -0.2px;
}

.garage-section {
  border: #e6e6e6 1px solid;
  border-radius: 8px;
  width: 1180px;
  height: 474px;
  background-color: #e6e6e6;
  margin-bottom: 40px;
  display: flex;
}

.garage-section .title {
  padding: 40px;
  width: 362px;
  text-align: left;
}

.split-section {
  width: 1180px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.split-section .inner-section {
  width: 570px;
  margin-bottom: 40px;
}

.meetup-section {
  border: #e6e6e6 1px solid;
  border-radius: 8px;
  background-color: #e6e6e6;
  height: 400px;
  margin-bottom: 40px;
  display: flex;
}

.meetup-section .title {
  width: 210px;
  padding: 40px 0 0 40px;
  text-align: left;
}

.community-section {
  border: #e6e6e6 1px solid;
  border-radius: 8px;
  background-color: #e6e6e6;
  width: 400;
  height: 400px;
  display: flex;
}

.community-section .title {
  width: 260px;
  padding: 40px 0 0 40px;
  text-align: left;
}

.navi-section {
  height: 840px;
  border: #e6e6e6 1px solid;
  border-radius: 8px;
  background-color: #e6e6e6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.navi-section .title {
  padding: 40px;
  text-align: left;
  width: calc(100% - 80px);
}

.forum-section {
  border: #e6e6e6 1px solid;
  border-radius: 8px;
  background-color: #e6e6e6;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.forum-section .title {
  text-align: left;
  padding: 40px 0 0 40px;
}

.tipping-section {
  display: flex;
  flex-direction: row;
  background-image: url(../assets/images/tipping-background.png);
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-color: #fff;
  border: #e6e6e6 1px solid;
  border-radius: 8px;
  height: 400px;
}

.tipping-section .title {
  width: 285px;
  text-align: left;
  padding: 40px;
}

.get-started-section-wrapper {
  display: flex;
  background-color: #002202;
  justify-content: center;
}

.get-started-wrapper {
  width: 1180px;
  padding: 80px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.get-started-wrapper .inner-section {
  width: 578px;
  display: flex;
  flex-direction: column;
}

.get-started-wrapper .inner-section .gear-token-wrapper {
  width: 100%;
  border-radius: 16px;
  background: linear-gradient(180deg, #91ee87 0%, #ffffff 51.56%);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.get-started-wrapper .inner-section .gear-token-wrapper .title {
  text-align: left;
  margin: 40px;
  font-size: 56px;
  font-weight: bold;
  line-height: 60px;
  letter-spacing: -2px;
  color: #002202;
}

.get-started-wrapper .inner-section .gear-token-wrapper img {
  width: 386px;
}

.get-started-wrapper .inner-section .sub-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
  gap: 20px;
}

.get-started-wrapper .inner-section .sub-section .panel {
  width: 237px;
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 700;
  border-radius: 16px;
  font-size: 36px;
  justify-content: flex-end;
  padding: 20px;
}

.get-started-wrapper .inner-section .sub-section .panel.color1 {
  background-color: #d2ef65;
}

.get-started-wrapper .inner-section .sub-section .panel.color2 {
  background-color: #a3abfe;
}

.get-started-wrapper .inner-section .sub-section .panel.color3 {
  background-color: #ffb4ab;
}

.get-started-wrapper .inner-section .sub-section .panel.color4 {
  background-color: #7df8d6;
}

.navi-section,
.meetup-section,
.tipping-section,
.inner-section,
.item-wrapper .item {
  position: relative;
}

.soon-icon-wrapper {
  position: absolute;
  top: 0;
  right: 0;
}

.footer-section-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
  gap: 48px;
  position: relative;
}

.footer-section-wrapper .footer-section-main {
  background-color: #002202;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1180px;
  padding: 190px 24px;
  border-radius: 16px;
  gap: 48px;
}

.footer-section-wrapper .footer-section-main .heading {
  width: 100%;
}

.footer-section-wrapper .footer-section-main .heading .item2 {
  font-size: 72px;
  font-weight: 700;
  color: white;
  margin: 0;
}

.footer-section-wrapper .footer-section-main .heading .item3 {
  font-size: 24px;
  font-weight: 400;
  color: white;
  margin: 0;
}

.footer-section-wrapper .footer-section-main .down-button {
  border-radius: 8px;
  border: none;
  background-color: #91ee87;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.4px;
}


.footer-section {
  background-color: #91ee87;
  width: 1180px;
  height: 555px;
  margin: 0 0 40px;
  border-radius: 16px;
  display: flex;
  column-gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.footer-section .text1 {
  background-color: #00530d;
  padding: 4px 20px;
  border-radius: 8px;
  font-size: 56px;
  font-weight: bold;
  color: white;
  letter-spacing: -2px;
  margin-bottom: 13px;
}

.footer-section .text2 {
  font-size: 56px;
  color: black;
  font-weight: bold;
  margin: -10px 0 40px;
}

.footer-section .footer-subscription-wrapper {
  display: flex;
  column-gap: 8px;
}

.footer-section .email-input {
  border-radius: 4px;
  height: 40px;
  font-size: 16px;
  padding: 8px 16px;
  border: solid 1px #141414;
  width: 317px;
}

.footer-section .join-button {
  padding: 0px 16px;
  border-radius: 8px;
  border: none;
  background-color: #141414;
  color: white;
  font-size: 16px;
}

.join-button,
.down-button,
.reserve-button {
  cursor: pointer;
  text-decoration: none;
  color: #141414;
}

.footer-section-wrapper .footer-information {
  display: flex;
  justify-content: space-between;
  width: 1100px;
}

.footer-information .copyright {
  margin: 0px;
}

.footer-information .link-section {
  display: flex;
  column-gap: 32px;
}

.footer-information .link-section .link-text {
  font-size: 16px;
  font-weight: 510;
  color: #858585;
}

.section-header-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  color: black;
  margin: 16px 0 0 0;
}

.section-header-cont {
  font-size: 18px;
  font-weight: 600;
  color: black;
  margin: 16px 0 0 0;
}

.slider-wrapper-vertical {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  justify-content: center;
  padding: 40px 0;
}

.marquee-vertical,
.marquee-vertical-reverse {
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: 16px;
  mask-image: linear-gradient(
    to bottom,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );
}

.marquee-vertical .marquee__group,
.marquee-vertical-reverse .marquee__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 16px;
  animation: scroll-y 30s linear infinite;
  flex-direction: column;
}

.marquee-vertical-reverse .marquee__group {
  animation-direction: reverse;
  animation-delay: -3s;
}

.slider-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: auto;
  max-width: 100%;
}

.marquee,
.marquee-reverse {
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: 16px;
  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );
}

.marquee .marquee__group,
.marquee-reverse .marquee__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 16px;
  animation: scroll-x 60s linear infinite;
}

.marquee-reverse .marquee__group {
  animation-direction: reverse;
  animation-delay: -3s;
}

.forum-item {
  height: 56px;
  border-radius: 32px;
  background-color: white;
  padding: 0 16px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin: 0;
}

.avatar-container {
  margin-left: 20px;
  border-radius: 50%;
  animation-name: floating;
  animation-play-state: running;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.avatar-container.avatar1 {
  animation-duration: 4s;
  left: 235px;
  top: 48px;
  position: absolute;
}

.avatar-container.avatar2 {
  animation-duration: 4s;
  animation-delay: 1s;
  left: 0px;
  top: 70px;
  position: absolute;
}

.avatar-container.avatar3 {
  animation-duration: 4s;
  animation-delay: 2s;
  left: 926px;
  top: 5px;
  position: absolute;
}

.avatar-container.avatar4 {
  animation-duration: 4s;
  animation-delay: 3s;
  left: 1042px;
  top: 180px;
  position: absolute;
}

.avatar-container.avatar5 {
  animation-duration: 4s;
  animation-delay: -1s;
  left: 876px;
  top: 414px;
  position: absolute;
}

.avatar-container.avatar6 {
  animation-duration: 4s;
  animation-delay: -2s;
  left: 43px;
  top: 380px;
  position: absolute;
}

.avatar-container.avatar7 {
  animation-duration: 4s;
  animation-delay: -3s;
  left: 532px;
  top: 22px;
  position: absolute;
}

.avatar60x60 {
  width: 60px;
  height: 60px;
}

.avatar80x80 {
  width: 80px;
  height: 80px;
}

@keyframes scroll-y {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(calc(-100% - 16px));
  }
}

@keyframes scroll-x {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 16px));
  }
}

@keyframes horizontal-scroll {
  to {
    transform: translateX(-50%);
  }
}

@keyframes floating {
  0% {
    transform: translateY(8px) rotate3d(0, 0, 1, 3deg);
  }

  100% {
    transform: translateY(-8px) rotate3d(0, 0, 1, 0deg);
  }
}

@media screen and (min-width: 1180px) {
  .section-wrapper .item-wrapper {
    display: none;
  }
}

@media screen and (max-width: 1179px) {
  .section-wrapper .garage-section,
  .section-wrapper .split-section {
    display: none;
  }

  .section-wrapper {
    padding: 48px 24px;
    width: calc(100% - 48px);
  }

  .section-wrapper .header-title {
    margin: 0;
  }

  .section-wrapper .item-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 24px;
    width: 100%;
  }

  .section-wrapper .item-wrapper .item {
    width: calc(50% - 40px);
    height: 128px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 8px;
    align-items: flex-start;
    background-color: #e6e6e6;
    border-radius: 8px;
  }

  .section-wrapper .item-wrapper .item .item-title {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    text-align: left;
  }

  .section-wrapper .item-wrapper .item .item-text {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    color: #484848;
    text-align: left;
  }
  .section-wrapper .item-wrapper .item.tipping {
    background-image: url(../assets/images/tipping-background.png);
    background-position-y: bottom;
    background-repeat: no-repeat;
  }

  .reserve-wrapper {
    width: calc(100% - 48px);
    padding: 48px 24px;
  }

  .reserve-wrapper .reserve-section {
    width: calc(100% - 48px);
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .reserve-wrapper .form-part {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }

  .reserve-wrapper .form-part .input-wrapper {
    position: relative;
    width: calc(100% - 48px);
    /* margin-left: -46px; */
  }

  .reserve-wrapper .form-part .input-email,
  .reserve-wrapper .form-part .input-username,
  .reserve-wrapper .form-part .claim-button {
    width: 100%;
  }


  .get-started-section-wrapper .get-started-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 48px 24px;
    width: 100%;
  }

  .get-started-section-wrapper .get-started-wrapper .inner-section {
    /* width: calc( 100% - 48px); */
    width: 100%;
  }

  .get-started-section-wrapper
    .get-started-wrapper
    .inner-section
    .gear-token-wrapper {
    font-size: 36px;
  }

  .get-started-wrapper .inner-section .gear-token-wrapper .title {
    font-size: 36px;
  }

  .get-started-section-wrapper .get-started-wrapper .inner-section .gear-token-wrapper img {
    width: 290px;
  }

  .get-started-section-wrapper
    .get-started-wrapper
    .inner-section
    .car-cafe-image {
    width: 100%;
  }

  .get-started-section-wrapper
    .get-started-wrapper
    .inner-section
    .car-cafe-image
    .cafe-image-style {
    width: 100%;
  }

  .get-started-section-wrapper
    .get-started-wrapper
    .inner-section
    .sub-section {
    gap: 16px;
    padding-top: 16px;
  }

  .get-started-section-wrapper
    .get-started-wrapper
    .inner-section
    .sub-section
    .panel {
    width: calc(50% - 32px);
    font-size: 18px;
    height: 93px;
  }

  .footer-section-wrapper {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .footer-section-wrapper .footer-section-main {
    width: calc(100% - 48px);
    padding: 48px 24px;
  }

  .footer-section-wrapper .footer-section {
    width: 100%;
    border-radius: 0;
  }

  .footer-section-wrapper .footer-section .text1,
  .footer-section-wrapper .footer-section .text2 {
    font-size: 36px;
  }

  .footer-section-wrapper .footer-section .email-input {
    width: 227px;
  }

  .footer-section-wrapper .footer-information {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
    padding: 48px 0;
  }

  .avatar-container {
    margin-top: 0px;
    margin-left: 0px;
  }

  .avatar-container img {
    width: 48px;
    height: 48px;
  }

  .avatar-container.avatar1 {
    left: 0;
    top: 136px;
  }

  .avatar-container.avatar2 {
    left: 21.3%;
    top: 62px;
  }

  .avatar-container.avatar3 {
    left: 82.8%;
    top: 93px;
  }

  .avatar-container.avatar4 {
    left: 79.7%;
    top: 436px;
  }

  .avatar-container.avatar5 {
    left: 4.1%;
    top: 428px;
  }

  .avatar-container.avatar6 {
    left: 33.6%;
    top: 475px;
  }

  .avatar-container.avatar7 {
    left: 57.7%;
    top: 22px;
  }
}

@media screen and (max-width: 1000px) {
  .card2,
  .card3,
  .card4 {
    display: none;
  }
}

@media screen and (max-width: 600px) {

  .banner-container .banner-action .button-wrapper,
  .banner-container .banner-action .button-wrapper .down-button
   {
    width: 100%;
  }

  .banner-container .banner-action .button-wrapper .reserve-button {
    display: none;
  }

  .banner-container .banner-action .heading .item2,
  .footer-section-wrapper .footer-section-main .heading .item2 {
    font-size: 44px;
  }

  .banner-container .banner-action .heading .item3,
  .footer-section-wrapper .footer-section-main .heading .item3 {
    font-size: 16px;
  }

  .banner-container .banner-action .button-wrapper .watch-button {
    display: none;
  }

  .menu-section .menu-wrapper .link-wrapper {
    display: none;
  }

  .menu-wrapper {
    padding: 0 8px 0 8px;
    width: calc(100% - 30px);
  }

  .menu-logo {
    width: 93px;
  }

  .proud-title {
    font-size: 16px !important;
  }

  .menu-wrapper .part1 {
    gap: 8px;
  }

  .banner-container {
    height: 1100px !important;
  }

  .banner-image {
    height: 667px !important;
    /* background-size: 256% 100% !important; */
  }

  .floating-banner-text-wrapper {
    bottom: -40px !important;
  }

  .floating-banner-text {
    font-size: 95px !important;
  }

  .footer-section-wrapper .footer-section-main .down-button {
    width: calc(100% - 48px);
  }

  #soonIcon {
    width: 80px;
    height: 80px;
  }

  .reserve-section .reserve-header .reserve-content {
    font-size: 28px;
  }

  .section-wrapper .header-title {
    font-size: 28px;
  }
}

@media screen and (max-width: 400px) {
  .proud-title {
    font-size: 12px !important;
  }
}
